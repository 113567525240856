<template>
  <section class="content">
    <div class="table-responsive">
      <table class="table table-hover" ref="tblstaffgaji">
        <thead>
          <tr>
            <th>STAFF</th>
            <th>NO. HP</th>
            <th>JUMLAH</th>
            <th>NOTE</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody @click="handleClick"></tbody>
      </table>
    </div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </p>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">STAFF</label>
                  <v-select :options="staffPic" v-model="form.staff_id" :reduce="opt => opt.id" />
                </div>
                <!-- <div class="col-md-6 form-group">
                  <label class="control-label">TANGGAL PEMBAYARAN AWAL</label>
                  <datepicker v-model="form.payment_start" id="payment_start" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">TANGGAL PEMBAYARAN AKHIR</label>
                  <datepicker v-model="form.payment_end" id="payment_end" />
                </div> -->
                <div class="col-md-6 form-group">
                  <label class="control-label">JUMLAH</label>
                  <input
                    id="jumlah"
                    class="form-control"
                    v-model="form.jumlah"
                    type="number"
                    name="jumlah"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">KETERANGAN</label>
                  <textarea
                    class="form-control"
                    id="note"
                    rows="3"
                    v-model="form.note"
                    placeholder="Tuliskan keterangan"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import datepicker from "@/components/Datepicker";
import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "",
  data() {
    return {
      errors: [],
      staffPic: [],
      staffPicOrigin: [],
      method: "",
      roles: "",
      formTitle: "Tambah Gaji Staff",
      form: {
        staff_id: "",
        jumlah: 0,
        note: "",
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    this.loadStaff();
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    loadStaff() {
      authFetch("/management/staff_gaji/staff").then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.staffPic = json.data
            this.staffPicOrigin = json.data
          }
        });
      });
    },
    onSearch(search, loading) {
      if (search.length > 3) {
        loading(true);
        var vm = this;
        authFetch(
          `/management/staff_gaji/cari_staff?q=${escape(search)}`
        ).then((res) => {
          res.json().then((json) => (json.items.length>0 ? vm.staffPic = json.items : vm.staffPic = this.staffPicOrigin));
          loading(false);
        });
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;

      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/management/staff_gaji";
      if (this.method == "PUT")
        urlSubmit = "/management/staff_gaji/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  components: {
    datepicker,
    datePicker,
    vSelect,
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblstaffgaji, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/management/staff_gaji",
      columns: [
        { data: "nama_staff" },
        { data: "no_hp" },
        { data: "jumlah",
        render: function (data, type, row, meta) {
          return "Rp " + formatCurrency(data);
        } },
        { data: "note" },
        {
          data: "status",
          render: function (data, type, row, meta) {
            return row.status == 1
              ? '<span class="badge badge-success">Active</span>'
              : '<span class="badge badge-warning">Not Active</span>';
          },
        },
      ],
      filterBy: [0, 1],
      rowCallback: function (row, data) {},
      paramData: function ( d ) {
        d.is_tentor = false;
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Gaji Staff";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Gaji Staff";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/management/staff_gaji/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
};
</script>
